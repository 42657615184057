<template>
  <div class="centergrid">
    <WelcomeDialogue />
    <v-carousel v-if="homeSlider && homeSlider.length > 0" cycle height="100vh" hide-delimiter-background
      delimiter-icon="mdi-minus">
      <v-carousel-item v-for="item in homeSlider" :key="item.productId" :src="item.productImages[0]" cover>
        <template v-slot:placeholder>
          <v-skeleton-loader class="mx-auto" width="100%" height="100%" type="image"></v-skeleton-loader>
        </template></v-carousel-item>
      <div class="text-center position-absolute top-50 start-50 translate-middle">
        <p :style="{ fontSize: '24px', fontWeight: 'bold', color: COLORS.WHITE }">
          A STYLE FOR EVERY STORY
        </p>
        <router-link to="/collection">
          <v-btn :style="{ color: COLORS.WHITE, fontWeight: 'bold', fontSize: '16px', textDecoration: 'underline' }"
            :color="COLORS.PRIMARY" text rounded>
            SHOP {{ collectionName }}
          </v-btn>
        </router-link>
      </div>
    </v-carousel>
    <vs-row style="padding: 64px 0;" justify="center">
      <v-slide-group show-arrows>
        <v-slide-item v-if="tops" style="padding: 0 1px;">
          <router-link to="/tops">
            <v-card :width="$vuetify.breakpoint.smAndDown ? 260 : 350" height="400" tile flat>
              <v-img height="100%" v-bind:src="tops.productImages[0]">
                <template v-slot:placeholder>
                  <v-skeleton-loader class="mx-auto" width="100%" height="100%" type="image"></v-skeleton-loader>
                </template>
              </v-img>
            </v-card>
            <div style="padding: 5% 0;">
              <h6> TOPS </h6>
            </div>
          </router-link>
        </v-slide-item>
        <v-slide-item v-if="bottoms" style="padding: 0 1px;">
          <router-link to="/bottoms">
            <v-card :width="$vuetify.breakpoint.smAndDown ? 260 : 350" height="400" tile flat>
              <v-img height="100%" v-bind:src="bottoms.productImages[0]">
                <template v-slot:placeholder>
                  <v-skeleton-loader class="mx-auto" width="100%" height="100%" type="image"></v-skeleton-loader>
                </template>
              </v-img>
            </v-card>
            <div style="padding: 5% 0;">
              <h6> BOTTOMS </h6>
            </div>
          </router-link>
        </v-slide-item>
        <v-slide-item v-if="dresses" style="padding: 0 1px;">
          <router-link to="/dresses">
            <v-card :width="$vuetify.breakpoint.smAndDown ? 260 : 350" height="400" tile flat>
              <v-img height="100%" v-bind:src="dresses.productImages[0]">
                <template v-slot:placeholder>
                  <v-skeleton-loader class="mx-auto" width="100%" height="100%" type="image"></v-skeleton-loader>
                </template>
              </v-img>
            </v-card>
            <div style="padding: 5% 0;">
              <h6> DRESSES </h6>
            </div>
          </router-link>
        </v-slide-item>
        <v-slide-item v-if="dressSets" style="padding: 0 1px;">
          <router-link to="/dressSets">
            <v-card :width="$vuetify.breakpoint.smAndDown ? 260 : 350" height="400" tile flat>
              <v-img height="100%" v-bind:src="dressSets.productImages[0]">
                <template v-slot:placeholder>
                  <v-skeleton-loader class="mx-auto" width="100%" height="100%" type="image"></v-skeleton-loader>
                </template>
              </v-img>
            </v-card>
            <div style="padding: 5% 0;">
              <h6> DRESS SETS </h6>
            </div>
          </router-link>
        </v-slide-item>
      </v-slide-group>
    </vs-row>
    <router-link v-if="bestSellers" to="/bestSellers">
      <div class="position-relative">
        <v-img :height="$vuetify.breakpoint.smAndDown ? '85vh' : '100vh'" v-bind:src="bestSellers.productImages[0]">
          <template v-slot:placeholder>
            <v-skeleton-loader class="mx-auto" width="100%" height="100%" type="image"></v-skeleton-loader>
          </template>
        </v-img>
        <div class="text-center position-absolute bottom-0 start-0"
          :style="{ width: '100%', padding: '10px', backdropFilter: 'blur(6px)' }">
          <p :style="{ fontSize: '24px', fontWeight: 'bold', color: COLORS.WHITE }">
            {{
              bestSellers.productTitle }}</p>
          <v-btn text
            :style="{ color: COLORS.WHITE, fontWeight: 'bold', fontSize: '16px', textDecoration: 'underline' }" rounded>
            SHOP BEST SELLERS
          </v-btn>
        </div>
      </div>
    </router-link>
    <router-link v-if="collection" to="/collection">
      <div class="position-relative">
        <v-img :height="$vuetify.breakpoint.smAndDown ? '85vh' : '100vh'" v-bind:src="collection.productImages[0]">
          <template v-slot:placeholder>
            <v-skeleton-loader class="mx-auto" width="100%" height="100%" type="image"></v-skeleton-loader>
          </template>
        </v-img>
        <div class="text-center position-absolute bottom-0 start-0"
          :style="{ width: '100%', padding: '10px', backdropFilter: 'blur(6px)' }">
          <p :style="{ fontSize: '24px', fontWeight: 'bold', color: COLORS.WHITE }">
            {{
              collection.productTitle }}</p>
          <v-btn text
            :style="{ color: COLORS.WHITE, fontWeight: 'bold', fontSize: '16px', textDecoration: 'underline' }" rounded>
            SHOP {{ collectionName }}
          </v-btn>
        </div>
      </div>
    </router-link>
    <div style="padding: 5% 0;">
      <vs-row justify="center">
        <v-col cols="12" md="3">
          <v-card flat class="d-flex flex-column align-center">
            <img src="../assets/bangladesh.png" width="120px">
            <v-card-title>MADE IN BANGLADESH</v-card-title>
            <v-card-text>
              Experience the perfect blend of Bangladeshi craftsmanship and contemporary style, offering a world-class
              design at a price that feels close to home.
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="3">
          <v-card flat class="d-flex flex-column align-center">
            <img src="../assets/quality.png" width="120px">
            <v-card-title>QUALITY MATERIALS</v-card-title>
            <v-card-text>
              Our garments are crafted with top-tier materials to guarantee a premium feel and lasting quality, so
              you’ll
              find yourself reaching for them time and again.
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="3">
          <v-card flat class="d-flex flex-column align-center">
            <img src="../assets/standard.png" width="120px">
            <v-card-title>HIGH STANDARDS</v-card-title>
            <v-card-text>
              From careful packaging and reliable delivery to attentive customer service, we aim to provide an
              experience
              that not only meets but exceeds your expectations.
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="3">
          <v-card flat class="d-flex flex-column align-center">
            <img src="../assets/tracking.png" width="120px">
            <v-card-title>TRACKED DELIVERY</v-card-title>
            <v-card-text>
              Sign in to monitor your order status in real-time and enjoy an even smoother shopping journey! You can
              also
              link your orders in your profile.
            </v-card-text>
          </v-card>
        </v-col>
      </vs-row>
    </div>
    <div style="padding: 5% 0;" v-if="testimonials && testimonials.length">
      <p :style="{ color: COLORS.BLACK, fontWeight: 'bold', fontSize: '28px', textAlign: 'center' }">
        Client Reviews</p>
      <vs-row justify="center">
        <v-slide-group show-arrows>
          <v-slide-item v-for="item in testimonials" v-bind:key="item" style="padding: 0 2px;">
            <v-card width="300" tile flat>
              <ImageGallery :item="item" :items="testimonials" />
            </v-card>
          </v-slide-item>
        </v-slide-group>
      </vs-row>
    </div>
  </div>
</template>

<script>
import { COLORS } from '@/plugins/branding.js';

export default {
  name: 'Home',
  data: () => ({
    COLORS,
  }),
  components: {
    WelcomeDialogue: () => import('@/components/WelcomeDialogue.vue'),
    ImageGallery: () => import('@/components/ImageGallery.vue'),
  },
  metaInfo() {
    return {
      meta: [
        {
          name: 'description',
          content: 'Discover the latest trends at Bengalina, Dhaka’s leading clothing brand. We offer exclusive Western and traditional wear, including tops, dresses, bottoms, and co-ords designed for every style story.',
        },
        {
          property: 'og:title',
          content: 'Bengalina - Dhaka, Bangladesh',
        },
        {
          property: 'og:description',
          content: 'Explore Bengalina’s latest collection featuring Western and traditional styles. From chic tops and elegant dresses to versatile bottoms and coordinated sets, Bengalina has your style covered.',
        },
        {
          name: 'keywords',
          content: 'Bengalina, clothing brand Dhaka, Bangladesh fashion, Western wear Bangladesh, traditional wear Dhaka, tops, dresses, bottoms, co-ords, dress sets, new collection, kameez',
        },
      ]
    }
  },
  computed: {
    collectionName() {
      return this.$store.state.collectionName;
    },
    testimonials() {
      return this.$store.state.testimonials;
    },
    bestSellers() {
      return this.$store.state.bestSellers.slice(0, 1)[0];
    },
    collection() {
      return [this.$store.state.newCollection[this.$store.state.newCollection.length - 1]][0];
    },
    homeSlider() {
      return this.$store.state.newCollection.slice(0, 3);
    },
    tops() {
      return this.$store.state.tops[0];
    },
    bottoms() {
      return this.$store.state.bottoms[0];
    },
    dresses() {
      return this.$store.state.dresses[0];
    },
    dressSets() {
      return this.$store.state.dressSets[0];
    },
  },
};
</script>
