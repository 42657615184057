<template>
  <div>
    <v-sheet :style="{ backgroundColor: COLORS.PRIMARY, width: '100%' }">
      <div style="padding: 20px">
        <vs-row style="padding: 50px" class="text-center">
          <vs-col style="padding: 24px 12px" :w="$vuetify.breakpoint.smAndDown ? 12 : 4">
            <router-link :style="{ textDecoration: 'none', color: 'inherit' }" :to="{ name: 'Support' }">
              <h3>Support</h3>
              <p>Size & Shipping</p>
              <p>Return & Exchanges</p>
              <p>FAQ</p>
            </router-link>
            <a @click="drawer = !drawer">Order Status</a>
          </vs-col>
          <vs-col style="padding: 24px 12px" :w="$vuetify.breakpoint.smAndDown ? 12 : 4">
            <h3>Contact Us</h3>
            <p>Email: {{ BRAND_EMAIL }}</p>
          </vs-col>
          <vs-col style="padding: 24px 12px" :w="$vuetify.breakpoint.smAndDown ? 12 : 4">
            <h3>Socials</h3>
            <a :href="BRAND_FACEBOOK" target="_blank" rel="noopener">
              <i :style="{ fontSize: '30px', padding: '5px', color: COLORS.FACEBOOK }"
                class="fa-brands fa-facebook"></i>
            </a>
            <a :href="BRAND_INSTAGRAM" target="_blank" rel="noopener">
              <i :style="{ fontSize: '30px', padding: '5px', color: COLORS.INSTAGRAM }"
                class="fa-brands fa-instagram"></i>
            </a>
          </vs-col>
        </vs-row>
        <vs-row style="font-size: 18px">
          <p>{{ BRAND_NAME }}</p>
          <span class="material-symbols-outlined"> pin_drop </span>
          <p>Dhaka, Bangladesh.</p>
        </vs-row>
        <p style="font-size: 12px">
          Copyright © {{ new Date().getFullYear() }} {{ BRAND_NAME }}. All rights reserved.
        </p>
      </div>
    </v-sheet>
    <v-bottom-sheet v-if="drawer" v-model="drawer" attach>
      <v-sheet style="height:80vh">
        <div style="padding: 14px;">
          <v-text-field v-model="orderId" placeholder="Order ID" label="Enter Order Id"></v-text-field>
          <v-btn rounded @click="getOrder" color="primary">Get Status</v-btn>
        </div>
        <Orders :orders="requestedOrder" :user="false" :requestedOrder="true" />
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
import { COLORS } from '@/plugins/branding.js';
import { BRAND_NAME, BRAND_EMAIL, BRAND_FACEBOOK, BRAND_INSTAGRAM } from '@/plugins/utils.js';

export default {
  name: 'Footer',
  components: {
    Orders: () => import('@/components/Orders.vue'),
  },
  data: () => ({
    drawer: false,
    orderId: null,
    requestedOrder: null,
    COLORS,
    BRAND_NAME,
    BRAND_EMAIL,
    BRAND_FACEBOOK,
    BRAND_INSTAGRAM,
  }),
  methods: {
    getOrder() {
      this.requestedOrder = this.$store.state.allOrders.filter(order => order.orderId == this.orderId);
    },
  },
};
</script>